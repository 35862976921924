@charset "UTF-8";
//media
$_widthpc: 1000px;
$_bpsmp: 768px;
//color
$_thin-txt-color: #c0c0c0;
$_txt-color: #5a5a5a;
$_leancolor: #f6b300;
$_yellow00: #ffd500;
$_yellow01: #ffb900;
$_yellow02: #ff9c00;
$_yellow03: #ff8000;
$_yellow04: #ff6400;
$_yellow05: #ff4600;

