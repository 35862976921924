@charset "UTF-8";
@import "_setting.scss";
@import "_mixin.scss";
@import "_reset.scss";

/* --- base --- */
:root {
	font-size: 62.5%
}
body{
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
	line-height: 1.5;
	color: $_txt-color;
	@include print {
		color: #000;
	}
}

//footer 固定用
html,body{
	height:100%;
}
#wrap{
	width: 100%;
	position: relative;
	min-height: 100%;
}
.container{
	padding-bottom:142px;
	@include media {
		padding-bottom:35px;
	}
}
footer{
	width: 100%;
	height:142px;
	position:absolute;
	bottom:0;
	@include media {
		height: 35px;
	}
}

//svgインクルード非表示
.img_defs {
	display: none;
}

//PC非表示
.smp {
	display: none;
	@include media {
		display: inline;
		height: 0;
	}
}
//SP非表示
.inline_pc {
	display: inline;
	@include media {
		display: none;
	}
}

//link
a {
	color: #5a5a5a;
	text-decoration: none;
	@include media {
		text-decoration: underline;
	}
}
a:hover {
	text-decoration: underline;
}

/* --- column --- */
.column_box {
	display: block;
	max-width: $_widthpc;
	margin: 0 auto;
	&:after {
		@include clearfix;
	}
}
.column {
	float: left;
	box-sizing: border-box;
	@include media {
		&:after {
			@include clearfix;
		}
	}
	&.span_1 {
		width: 20%;
		@include media {
			width: 100%;
		}
	}
	&.span_2 {
		width: 40%;
		@include media {
			width: 100%;
		}
	}
	&.span_3 {
		width: 60%;
		@include media {
			width: 100%;
		}
	}
	&.span_4 {
		width: 80%;
		@include media {
			width: 100%;
		}
	}
}

/* --- structure --- */
.container {
	@include fz(13);
	padding-top: 60px;
	@include media {
		padding-top: 0;
	}
}
.contents {
	position: relative;
	&.company,&.works {
		background-color: #efefef;
		@include print {
			background-color: #fff;
		}
	}
	&.access,&.recruit,&.contact {
		background-color: #fff;
		@include print {
		}
	}
}
.contents_head {
	display: none;
	@include media {
		display: block;
		width: 120px;
		padding-top: 20px;
		h2 {
			padding: 14px 0;
			text-align: center;
			line-height: 1;
			background-color: $_leancolor;
		}
		.hedding {
			height: 16px;
			width: 120px;
			fill: #fff;
		}
	}
}
.contents_body {
	max-width: 850px;
	margin: 0 auto;
	padding: 60px 15px;
	&:after {
		@include clearfix;
	}
	@include media {
		padding: 15px;
	}
}

.column_hedding {
	display: table-cell;
	min-width: 200px;
	@include media {
		margin-bottom: 10px;
		display: block;
		width: auto;
	}
	h3,h2 {
		border-left: 5px solid $_yellow03;
		padding-left: 10px;
		font-weight: normal;
		@include fz(18);
		span {
			display: block;
			@include fz(20);
			@include media {
				display: inline-block;
				padding-left: 5px;
			}
		}
	}
}
.column_contents {
	display: table-cell;
	width: 650px;
	vertical-align: top;
	@include media {
		display: block;
		width: auto;
	}
}

/* --- component --- */
//logo
.logo_contact {
	text-align: center;
	padding-top: 60px;
	@include media {
		padding-top: 0;
	}
}
.logo_color2{fill:#EC6C00;}
.logo_color3{fill:#F7B400;}
.logo_color1{fill:#595757;}

.logo {
	&.main {
		width: 250px;
		height: 80px;
		margin :10px 0 0 10px;
		@include media {
			width: 130px;
			height: 40px;
			margin :5px 0 0 10px;
		}
	}
	&.footer {
		width: 100px;
		height: 30px;
		fill: #fff;
	}
	&.contact {
		width: 200px;
		height: 64px;
		@include media {
			width: 125px;
			height: 40px;
		}
	}
}

//header
header h1{
	display: none;
}
.header_smp {
	display: none;
	@include media {
		display: block;
		height: 50px;
		background-color: #fff;

		#contact & {
			height: inherit;
		}
	}
}
.menu_btn{
	display: none;
	@include media {
		display: inline-block;
		width: 35px;
		height: 35px;
		padding: 0;
		position: absolute;
		right: 10px;
		top: 5px;
		background: none;
		border: none;
		border-radius: 6px;
		cursor: pointer;
		transition: all .3s;
		&:focus {
			outline: none;
		}
		.menu_img {
			width: 35px;
			height: 35px;
			fill: $_yellow03;
		}
	}
}

.main_img{
	min-height: 400px;
	position: relative;
	@include media {
		min-height: 270px;
	}
	@include print {
		min-height: 69px;
	}
	.logo_block {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-image: -moz-linear-gradient(to right, rgba(255,255,255,0.8), rgba(255,255,255,0.1));
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ccffffc8', endColorstr='#11ffffff', GradientType=1)";
		background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.8), rgba(255,255,255,0.1));
		background-image: -webkit-linear-gradient(to right, rgba(255,255,255,0.8), rgba(255,255,255,0.1));
		background-image: linear-gradient(to right, rgba(255,255,255,0.8), rgba(255,255,255,0.1));
		@include print {
			top: 0;
			background: none;
			border-bottom: 1px solid #5a5a5a;
		}
	}
	.lead_txt {
		margin: 0 0 10px 10px;
		@include fz(12);
		@include media {
			margin: 0 0 5px 10px;
			@include fz(10);
		}
	}
	.lead_img {
		position: relative;
	}
	.bg_img {
		position: absolute;
		width: 100%;
		min-height: 400px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		z-index: -1;
		@include media {
			min-height: 220px;
		}
	}
	.fadein {
		opacity: 1;
		transition: opacity 1.5s;
	}
	.fadeout {
		opacity: 0;
		transition: opacity 1.5s;
	}
}

//global nav
#global_nav {
	display: block;
	height: 60px;
	background-color: #fff;
	width: 100%;
	position: absolute;
	z-index: 3;
	@include media {
		display: none;
	}
	ul {
		background-color: #fff;
			@include media($_widthpc) {
			padding: 0 15px;
		}
	}
	.nav_list {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 15px;
			left: 0;
			border-right: 1px solid #c7c7c7;
			height: 30px;
		}
		&:last-child:after {
			content: "";
			position: absolute;
			top: 15px;
			right: 0;
			border-right: 1px solid #c7c7c7;
			height: 30px;
		}
		.nav {
			width: 130px;
			height: 20px;
			fill: $_txt-color;
		}
	}
}
.nav_list a{
	display: block;
	padding: 20px 0 15px;
	color: #fff;
	@include fz(20);
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	line-height: 1;
	height: 20px;
	border-bottom: 5px solid #fff;
	transition: all .3s;
	&:hover {
		border-color: $_leancolor;
	}
	@include media {
		border-bottom: 0;
	}
}

//global nav smp
#global_nav_smp {
	display: none;
	@include media {
		display: none;
		position: absolute;
		top: 50px;
		width: 100%;
		z-index: 3;
		ul{
			border: 1px solid #E6E6E6;
			border-top: none;
			background-color: #fff;
		}
		.nav_list {
			border-top: 1px solid #ccc;
			a{
				display: block;
				padding: 20px;
				background-color: #fff;
				color: $_txt-color;
				font-weight: bold;
				text-align: left;
				&:hover {
					opacity: 1.0;
				}
			}
			&:first-child {
				border-top: none;
			}
			&.menu_close {
				display: block;
				height: 60px;
				background-color: #fff;
				cursor: pointer;
			}
			.close_img {
				width: 20px;
				height: 20px;
				padding: 20px 0;
				fill: $_txt-color;
				position: absolute;
				right: 20px;
			}
		}
	}
}

//footer
footer {
	background-color: $_txt-color;
	color: #fff;
	@include print {
		background-color: #fff;
		color: $_txt-color;
	}
	a {
		color: #fff;
	}
	.footer_block {
		width: 850px;
		padding: 30px 0 0 0;
		&:after {
			@include clearfix;
		}
		@include media {
			display: none;
		}
	}
	.footer_logo {
		float: left;
	}
	.address {
		float: left;
		margin-left: 30px;
		@include fz(13);
	}
	.copy {
		padding: 10px 0 10px;
		text-align: center;
		@include fz(10);
		@include media {
			padding: 10px 0;
		}
	}
}

//popup
#p_mask {
	display: none;
	&.on {
		position: fixed;
		display: block;
		top: 0;
		width: 100%;
		z-index: 100;
		background-color: rgba(255, 255, 255, 0.8);
		overflow: auto;
		opacity: 0;
	}
	&.fadein {
		opacity: 1;
		transition: opacity .5s;
	}
	&.fadeout {
		opacity: 0;
		transition: opacity .5s;
	}
}
#p_ctts_wrap {
	display: none;
	&.on {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		max-width: 880px;
		max-height: 570px;
		z-index: 100;
	}
	#p_img_wrap {
		position: relative;
		margin: 0 15px;
		height: 100%;
		overflow: hidden;
		.p_img{
			position: absolute;
			width: 100%;
			&.slidein {
				opacity: 1;
				transition: all .7s;
				&.plus,
				&.minus {
					margin-left: 0;
				}
			}
			&.slideout {
				z-index: 1;
				opacity: 0;
				transition: all .7s;
				&.plus {
					margin-left: 5%;
				}
				&.minus {
					margin-left: -5%;
				}
			}
		}
	}
	button {
		z-index: 101;
		position: absolute;
		cursor: pointer;

		//--user agent chrome
		padding: 0;
		border: none;
		outline: none;
		background: none;
	}
	#p_prev,
	#p_next {
		top: calc(50% - 25px);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #000;
		opacity: 0.3;
		&:before {
			content: "";
			width: 18px;
			height: 18px;
			top: 16px;
			right: 12px;
			border: solid 3px transparent;
			border-top: solid 3px #fff;
			transform: rotate(-45deg);
			position: absolute;
			box-sizing: border-box;
		}
		&:hover {
			opacity: 0.6;
		}
		@include media {
			width: 40px;
			height: 40px;
			&:before {
				top: 13px;
				width: 14px;
				height: 14px;
			}
		}
	}
	#p_prev {
		left: -50px;
		&:before {
			border-left: solid 3px #fff;
		}
		@include media($_widthpc) {
			left: 25px;
		}
		@include media {
			left: 20px;
			&:before {
				right: 9px;
			}
		}
	}
	#p_next {
		right: -50px;
		&:before {
			right: 20px;
			border-right: solid 3px #fff;
			transform: rotate(45deg);
		}
		@include media($_widthpc) {
			right: 25px;
		}
		@include media {
			right: 20px;
			&:before {
				right: 14px;
			}
		}
	}
}

//list
.content_list{
	dt {
		float: left;
		width: 200px;
		margin-top: 10px;
		&:first-child ,&:first-child + dd{
			margin-top: 0;
		}
		@include media {
			float: none;
			width: auto;
			&:before {
				content: "\0025a0";
			}
		}
	}
	dd {
		margin: 10px 0 0 200px;
		@include media {
			margin: 0 0 0 1em;
		}
		&:after {
			@include clearfix;
		}
	}
}
.list_inside li {
	list-style: none;
	margin-top:2px;
	padding-left: 1.2em;
	text-indent: -1.2em;
	&:before {
		content: "\0030fb";
	}
}
.news_list {
	li {
		position: relative;
		list-style: none;
		margin-bottom: 15px;
		padding: 5px 0 15px 110px;
		border-bottom: solid 1px;
		border-style: dashed;
		word-wrap: break-word;
		&:nth-last-of-type(1) {
			border-bottom: none;
		}
		span {
			position: absolute;
			left: 0;
		}
		a {
			text-decoration: underline;
		}
	}
}
.thumbnail_list {
	display: flex;
	flex-wrap: wrap;
	margin: 25px -0.7%;
	@include media {
		margin: 25px -1.4%;
	}
	li {
		flex-basis: 15.26%;
		margin: 0 0.7%;
		@include media {
			flex-basis: 30.5%;
			margin: 0 1.4%;
		}
		img {
			width: 100%;
		}
	}
}
.station_list {
	margin-top: 20px;
	dt {
		width: 130px;
		@include media {
			width: auto;
		}
	}
	dd {
		margin: 10px 0 0 130px;
		@include media {
			margin: 0 0 0 1em;
		}
	}
}
.profile_address_list {
	dt {
		width: 17em;
		&:before {
			content: "";
		}
		@include media {
			width: auto;
		}
	}
	dd {
		margin: 10px 0 0 17em;
		@include media {
			margin: 0 0 0 1em;
		}
	}
}
.pc-pl2{
	padding-left:2em;
	@include media {
		padding-left:0;
	}
}
.pc-pl5{
	padding-left:5em;
	@include media {
		padding-left:0;
	}
}
.profile_subtitle {
	margin: 10px 0 10px 0;
	@include media {
		dt {
			&:before {
				content: "";
			}
		}
	}
}
.station_address_list {
	dt {
		width: 7em;
		&:before {
			content: "";
		}
		@include media {
			width: auto;
			margin: 0 0 0 1em;
		}
	}
	dd {
		margin: 10px 0 0 7em;
		@include media {
			margin: 0 0 0 1em;
		}
	}
}
.list_none{
	list-style: none;
}

//iframe
.map iframe {
	width: 100%;
	height: 300px;
	border: none;
}

//backtop
.backtop {
	max-width: $_widthpc;
	margin: 0 auto;
	height: 50px;
	position: relative;
	bottom: 30px;
	@include media {
		bottom: 10px;
	}
	@include print {
		display: none;
	}
}
.arrow {
	width: 22px;
	height: 10px;
	padding: 20px;
	fill: $_txt-color;
	position: absolute;
	right: 0;
}
span.thin { 
	@include fz(10);
	color: $_thin-txt-color;
}
//form
input,select,textarea,button {
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
}
.form_input {
	input,
	select {
		width: 70%;
		&.required {
			background-color: #fffccc;
		}
		&:focus {
			outline: none;
			border: 1px solid $_leancolor;
			background-color: #fff;
		}
		&.change {
			background-color: #fff;
		}
		@include media {
			width: 100%;
			padding: 8px;
			@include fz(16);
		}
	}
	//--user agent select
	select {
		border-radius: 0;
		background: url('../img/select_bg.png') no-repeat center right 10px;
		-moz-appearance: none;
		-webkit-appearance: none;
		&::-ms-expand {
			display: none;
		}
	}
	textarea {
		width: 100%;
		background-color: #fffccc;
		&:focus {
			outline: none;
			border: 1px solid $_leancolor;
			background-color: #fff;
		}
		&.change {
			background-color: #fff;
		}
	}
	.form_item {
		margin-top: 24px;
		position: relative;
		a {
			color: $_yellow03;
		}
		&.privacy {
			margin-left: 180px;
			@include media {
				margin-left:0;
			}
		}
		&.button {
			text-align: right;
		}
		&.error {
			dd.error {
				position: absolute;
				left: -3px;
				margin-top: -3px;
				color: #fff;
				@include fz(11);
				background-color: $_yellow05;
				padding: 2px 6px;
				border-radius: 2px;
				z-index: 2;
				&:before, &:after {
					content: "";
					bottom: 100%;
					left: 1em;
					border: solid transparent;
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}
				&:after {
					border-top: 6px solid transparent;
					border-right: 4px solid transparent;
					border-bottom: 6px solid $_yellow05;
					border-left: 4px solid transparent;
				}
			}
			input, select, textarea {
				border: 1px solid $_yellow05;
			}
		}
		dt {
			float: left;
			width: 180px;
			padding: 5px 0;
			@include media {
				float: none;
			}
			.required {
				color: $_yellow04;
			}
		}
		dd {
			margin-left: 180px;
			@include media {
				margin-left:0;
			}
		}
	}
}
.form_confirm {
	margin-top: 15px;
	dl {
		background-color: #e6e6e6;
	}
	.form_item {
		width: 100%;
		border: 1px solid #c8c8c8;
		border-bottom: none;
		box-sizing: border-box;
		line-height: 16px;
		line-height: 1.6rem;
		&:after {
			@include clearfix;
		}
		&:nth-last-of-type(1) {
			border-bottom: 1px solid #c8c8c8;
		}
		&.button {
			border: none;
			margin-top: 15px;
			&:after {
				@include clearfix;
			}
		}
		&.hidden {
			display: none;
		}
		dt {
			float: left;
			width: 180px;
			padding: 10px;
			@include media {
				float: none;
				width: auto;
				border-bottom: 1px solid #c8c8c8;
				padding: 6px 10px;
			}
		}
		dd {
			margin-left: 200px;
			padding: 10px;
			min-height: 16px;
			background-color: #fff;
			border-left: 1px solid #c8c8c8;
			word-wrap: break-word;
			@include media {
				margin-left:0;
				min-height: 14px;
				line-height: 14px;
				line-height: 1.4rem;
				border-left: none;
			}
			pre.text {
				font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
				white-space: pre-wrap;
				word-wrap: break-word;
				font-size: inherit;
			}
		}
	}
	.form_button {
		float: right;
		&:first-child {
			float: left;
			margin-left: 0;
		}
	}
}
.form_button {
	width: 100px;
	padding: 5px 0;
	background-color: $_leancolor;
	border: none;
	margin-left: 10px;
	transition: all .3s;
	&:hover {
		background-color: rgba(255, 185, 0, 0.6);
		border: none;
		cursor: pointer;
	}
	&:focus {
		outline: none;
		border: none;
	}
	&:disabled {
		color: #888;
		background-color: #eee;
		border-color: #eee;
		&:hover {
			background-color: #eee;
			border-color: #eee;
			cursor: default;
		}
	}
	@include media {
		width: 120px;
		padding:15px 0;
		@include fz(16);
	}
}
.form_item.complete {
	margin-top: 20px;
	.form_button {
		display: inline-block;
		color: $_txt-color;
		text-decoration: none;
		text-align: center;
		margin-left: 0;
		&:hover {
			text-decoration: none;
		}
	}
}
.mail {
	font-weight: bold;
	margin-top: 10px;

}
.access_block {
	margin-top: 40px;
	&:first-of-type {
		margin-top: 0;
	}
}
.works_block {
	padding-top: 40px;
	&:first-child {
		padding-top: 0;
	}
}
.works_lead {
	@include fz(20);
	font-weight: normal;
	border-left: 5px solid #{$_yellow03};
	padding-left: 10px;
	@include media {
			@include fz(16);
	}
}
.works_text {
	padding: 15px 0;
	p {
		padding-top: 1em;
		&:first-child {
			padding-top: 0;
		}
	}
}
.works_list {
	padding: 10px 20px;
	margin-top: 15px;
	border-radius: 4px;
	background-color: #fbfbfb;
	@include media {
		padding: 10px;
	}
	dt {
		font-weight: bold;
	}
	@include print {
		background-color: #fff;
		border: 1px solid $_leancolor;
	}
}
.recruit_list {
	padding: 10px 0;
	&:first-child {
		padding-top: 0;
		border-top: none;
	}
	a {
		color: $_yellow03;
	}
	.treatment_list {
		dt {
			width: 80px;
			&:before {
				content: "\0030fb";
			}
		}
		dd {
			margin: 10px 0 0 80px;
			@include media {
				margin: 0 0 0 1em;
			}
		}
	}
}

.privacy_block {
	max-width: 940px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 20px;
	margin: 0 auto;
	ol {
		margin-left: 2em;
	}
	p {
		padding-bottom: 10px;
	}
	h1 {
		font-size: 2.4rem;
		padding-bottom: 10px;
		padding-top: 40px;
	}
	h2 {
		font-size: 1.8rem;
		padding-bottom: 5px;
	}
	.notes {
		text-align: center;
		padding-bottom: 20px;
		padding-top: 10px;
	}
	a {
		color: $_yellow03;
	}
}
