/*! 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, pre, address, code,
em, img, kbd, samp,
small, strong, sub, sup, var,b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size: 100%;
	vertical-align:baseline;
	background:transparent;
}

body {
	line-height:1;
}

article,aside,
footer,header,nav,section { 
	display:block;
}

address {
	font-style: normal;
}

nav ul{
	list-style:none;
}

a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}

table {
	border-collapse:collapse;
	border-spacing:0;
}

/* change border colour to suit your needs */
hr {
	display:block;
	height:1px;
	border:0;
	border-top:1px solid #cccccc;
	margin:1em 0;
	padding:0;
}

input, select,textarea {
	vertical-align:middle;
	border: 1px solid #c8c8c8;
	padding: 5px;
	box-sizing: border-box;
}