@charset "UTF-8";
//layout
@mixin clearfix {
	content: "";
	display: block;
	clear: both;
}

//smp
//$_bpsmpの値は_setting.scssに。
@mixin media($max-width: $_bpsmp) {
	@media print,screen and (max-width: #{$max-width}){
		@content;
	}
}
//print
@mixin print {
	@media print {
		@content;
	}

}

//font size
@mixin fz($size) {
	font-size: $size + px;
	font-size: calc($size / 10) * 1rem;
}

//PC global_nav
@mixin navhover($color) {
	a{
		background-color: $color;
		&:hover,&.stay {
			opacity: 1.0;
			background: rgba($color, 0.3);
			.nav {
				fill: $color;
			}
		}
	}
}
